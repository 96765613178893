import React from "react"
import { ArticleContentProps } from "../components/article-content/ArticleContent"
import { PageProps } from "gatsby"

const ClientSideOnlyLazy = React.lazy(() => import('../sections/Article'));

const Article: React.FC<PageProps<object, ArticleContentProps>> = (props) => {
  return (
    <React.Suspense fallback={''}>
      <ClientSideOnlyLazy {...props}/>
    </React.Suspense>
  );
}

export default Article